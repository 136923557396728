.reactTable {
  // border:3px solid red;
}
.forTheTable {
  // border:2px solid green;

  width: 70%;
  margin: 0 auto;
  margin-left: 400px;
}
.table1 {
  // bordeR:3px solid black;
  width: 80% !important;
}

// -----search--------------------
.searchs {
  // border:3px solid red;
  display: flex;
  justify-content: center;
  margin-top: 2vh;
}

.searchIcon {
  // border:2px solid red;
  padding-left: 2.1vh;
  font-size: 30px;
  color: #f04a3e;
  padding-top: 0.3vh;
}
i.fa-solid.fa-magnifying-glass.searchIcon {
  font-size: 22px;
  padding: 6px;
  padding-left: 10px;
}
.SEA-RANK {
  border-radius: 6px;
  width: 17%;
  display: flex;
  padding: 3px;
  background-color: #ffffff;
  // box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 19px 0 rgba(0, 0, 0, 0.19);
  height: 40px;
}

.searchInput {
  width: 100%;
  height: 100%;
  // padding: 5px !important;
  padding-left: 2px;
  text-decoration: none !important;
  outline: none !important;
  border: none !important;
  font-size: 20px !important;
  // border:3px solid red ;
  background-color: transparent !important;
  color: #000000 !important;
}

@media screen and (max-width: 990px) {
  .SEA-RANK {
    width: 45% !important;
  }
}

@media screen and (max-width: 770px) {
  .SEA-RANK {
    width: 55% !important;
  }
}

@media screen and (max-width: 630px) {
  .SEA-RANK {
    width: 65% !important;
  }
}

@media screen and (max-width: 430px) {
  .SEA-RANK {
    width: 75% !important;
  }
}

.next {
  cursor: pointer;
}

// -----Pagination-----------

.PageSCROL {
  // bordeR:3px solid red;

  width: 100%;
  // max-width: 160%;
  margin-bottom: 20px;
}

.panginations {
  // border:2px solid red !important;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.pageparts {
  border: 9px solid rgb(44, 44, 44) !important;
}

.paginpart {
  // border:1px solid rgb(44, 44, 44) !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.showingindex {
  // bordeR:2px solid green ;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SHOWINDEX1 {
  // border:2px solid red;
  padding: 1vh;
  font-weight: 100px;
  font-size: 16px;
}

.pages {
  color: #000000 !important;
}

li.page-item.next.disabled {
  cursor: not-allowed !important;
  pointer-events: none !important;
}

.disabled-link {
  color: #817f7f !important;
  cursor: none !important;
  pointer-events: none !important;
}

// -------paginations-----------
li.active {
  background-color: none !important;
}

.page-item.active .page-link {
  background-color: transparent !important;
  // border-color: antiquewhite !important;
  // border-radius: 5px !important;
  // background: #fff !important;
  // box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset, 2px 0px 4px 0px rgba(0, 0, 0, 0.25) inset !important;
  color: black !important;
}

.page-item .selectedpge {
  color: #000000 !important;
  border: none !important;
}

@media screen and (max-width: 836px) {
  .PageSCROL {
    // border:8px solid green !important;
    padding: 2vh;
    display: grid !important;
    justify-content: center !important;
    grid-template-rows: 4fr !important;

    .showingindex {
      width: 100% !important;
    }

    .paginpart {
      // border:4px solid blue !important;
      width: 100% !important;
      justify-content: center !important;
      display: flex !important;
    }

    .panginations {
      display: flex !important;
      justify-content: center !important;
      flex-direction: column !important;
      height: 100% !important;
      gap: 2vh;

      // border:6px solid green !important;
      align-items: center !important;
    }
  }
}

@media screen and (max-width: 420px) {
  .PageSCROL {
    justify-content: center !important;
    align-items: center !important;
    // bordeR:6px solid rgb(70, 209, 15) !important
  }

  .panginations {
    width: 90% !important;
    margin-left: 2vh !important;
  }
}

@media screen and (max-width: 406px) and (min-width: 225px) {
  .panginations {
    width: 86% !important;
    // border:4px solid red !important;
  }
  .NAV_PART {
    // border:6px solid rgb(0, 0, 0) !important;
    margin-left: 2vh !important;
    // width: 23vh;
  }
  // .MAIN_PAGES{
  //     border:5px solid green !important;
  // }
}

@media screen and (max-width: 377px) {
  .PageSCROL {
    // bordeR:4px solid red !important;
    // padding-top: 5vh;
    // padding-left: 9vh;
    // padding-right: 9vh;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    // bordeR:4px solid red !important;
  }
  .panginations {
    width: 100% !important;

    // border:4px solid rgb(3, 3, 3) !important;
    // margin-left: 15vh !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .MAIN_PAGES {
    // border:9px solid red !important;
    margin-left: -4.1vh !important;
  }
  .SHOWINDEX1 {
    font-size: 12px !important;
  }
  .page-item {
    font-size: 1vh !important;
  }
}
