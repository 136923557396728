// ------------------------pwd--------------------
.NewPassword{

   display: flex;
   gap:9px;
   flex-direction: column;
   width: 100%;
}
// --------------------input----fileds----------------------
.NewPassword-Filed{
    bordeR:2px solid rgb(129, 123, 123);
    width: 100%;
     display: flex;
    height: 6vh;
}
.Input-Field{
    background-color: transparent !important;
    outline: none !important;
    border:none  !important;
    width: 100%;
    height: 100%;
    padding-left: 2vh;
}
//icons-----------------
.icons{
    margin-top: 1vh !important;
}

.activate{
    -webkit-text-security:none;
}

.notactivate{
    -webkit-text-security:disc;
}

// --------------------BTN---RESTART-------------------
.btn-restart_CLICK{
    
    font-weight: 700;
    color: white;
    background-color: #003e83;
    border-radius: 10px;
    border: none;
    font-size: 1rem;
    width: 70%;
    height: 60px;
}
