  .CF_HEAD{
        font-size: 18px;
        font-weight: 300;
        text-align: left;
       

    } 
  // @media only screen and (max-width: 505px) {
  //   .published_table_wrapper {
  //     z-index: -10;
  //     left: 30vw;
  //     width: 50%;
  //     overflow: scroll;

  //     .CF_HEAD{
  //       font-size: 12px;
  //     }
  //   }
  // }

  
  .form-check .form-check-input {
    cursor: pointer;
    width: 45px;
    height: 20px;
    margin-top: 0.25em;
    vertical-align: top;
    background-color: tomato ;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    appearance: none;
  
}
.form-check-input:checked {
  background-color: #43b841;
  // border-color: #43B841;
}
 // .form-switch
 .form-check-input:checked {
  background-position: right center !important;
  // background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e);
}
// .form-switch
.form-check-input {
  // width: 2em;
  margin-left: -2.5em;
  // background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e);
  background-position: left center !important;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}

.published_page_wrapper .form-check .form-check-input {
    cursor: pointer;
    width: 45px;
    height: 20px;
    margin-top: 0.25em;
    vertical-align: top;
    background-color: tomato;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    appearance: none;

}


.published_page_wrapper .form-check .form-check-input:checked {
    background-position: right center !important;
}
.published_page_wrapper .form-check .form-check-input:checked {
    background-color: #43b841;
}
.published_page_wrapper .form-check .form-check-input {
  margin-left: -2.5em;
  background-position: left center !important;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
.testing .active{
  background-color: #43b841 !important;
  bordeR:7px solid red !important;
}

.testing .active span .cell{
  bordeR:7px solid red !important; 
}

