div.dataTables_wrapper div.dataTables_length select {
  //  color: brown !important;
  width: 50px !important;
}

.cancel-button {
  background-color: white;
  border-style: solid;
  border-radius: 8px;
  font-size: 16px;
  padding: 10px 30px;
}

.cancel-button:hover {
  background-color: #f1f6f9;
}

.save-button {
  background-color: #132c3b;
  border-radius: 8px;
  font-size: 16px;
  padding: 10px 30px;
  color: white;
  border-width: 0;
}

.save-button:hover {
  background-color: #35465e;
}
.lable {
  font-size: 20px;
  font-weight: 500;
  color: black;
}
.modelman {
  padding-left: 110px;
  padding-right: 10px;
}
