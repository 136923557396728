.user_uploaddata_table_wrapper {
  // border: 2px solid green;
  // position: relative;
  // bottom: 50vh;
  width: 100%;
  font-family: "Open Sans", sans-serif;

  div.dataTables_wrapper div.dataTables_length select {
    // color: brown !important;
    width: 60px !important;
  }

  .custom-datatable {
    // font-family: "Roboto";

    margin-top: 100px;
    // border: 2px solid red;

    table {
      font-size: 0.9rem;
      font-weight: 400;
      // font-family: "Roboto";
      font-family: "Open Sans", sans-serif;
      font-size: 14px;
      // color: aqua;
    }
  }

  .userdata-video {
    // border: 2px solid blue;
    width: 200px;
    height: 200px;
  }
  .userdata-image {
    // border: 2px solid blue;
    width: 250px;
    height: 200px;
  }

  .bbb {
    padding: 10px;
    padding-left: 30px;
  }
  
  

  .form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem;

    .form-check-input {
      cursor: pointer;
      width: 45px;
      height: 20px;
      margin-top: 0.25em;
      vertical-align: top;
      // background-color: #D01717;
      background-color: tomato;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      // border: 1px solid rgba(0,0,0,.25);
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      -webkit-print-color-adjust: exact;
      color-adjust: exact;
    }

    .form-check-input:checked {
      background-color: #43b841;
      // border-color: #43B841;
    }

    // .form-switch
    .form-check-input:checked {
      background-position: right center !important;
      // background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e);
    }
    // .form-switch
    .form-check-input {
      // width: 2em;
      margin-left: -2.5em;
      // background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e);
      background-position: left center !important;
      border-radius: 2em;
      transition: background-position 0.15s ease-in-out;
    }

    .modal-backdrop {
      background-color: #ff0000 !important; /* Replace with your desired color */
    }

    .mb-react-table .rt-show-entries select::-ms-expand,
    .mb-react-table .rt-show-entries select::-webkit-select-arrow {
      display: none !important;
    }
  }

  .modal-backdrop {
    background-color: #ff0000 !important; /* Replace with your desired color */
  }
}

.deleteButton {
  // background-color: #cc3300;
  // background-color: #f06347;
  background-color: #b44935;
  border: none;
  color: white;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 5px;
  font-family: "Open Sans", sans-serif;
  // letter-spacing: 1px;
  font-weight: 500;
}
.bbb .active{
  background-color: #43b841 !important;
}

.active-row {
  background-color: #f0f0f0; /* Set your desired highlight color here */
}



