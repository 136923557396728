.outbor {
  // border: 1px solid red;

  .navbar {
    // position: fixed;
    width: 100%;
    // border: 1px solid #0f3787;
    background-color: white;
    z-index: 999;
    // box-shadow: 0px 20px 20px 0px rgb(255 255 255 / 100%);
    display: flex;
    justify-content: space-between;

    .saveAllLogo {
      width: 95px;
      margin-left: 15px;
      position: relative;
      top: 5px;
    }

    .nav-option-wrapper {
      width: 100%;
      // border: 2px solid;
      display: flex;
      justify-content: end;
      gap: 30px;
      margin-right: 20px;

      .notification-wrapper {
        // border: 1px solid;
        width: 50px;
        border-radius: 50%;
        height: 50px;
        padding: 10px;
        background-color: #f2f2f2;
        svg {
          margin-left: 2px;
          color: #0f3787;
        }
        .badge {
          position: relative;
          top: -45px;
          right: -15px;
          // padding:3px 7px 3px 7px;
          border-radius: 50%;
          background: red;
          color: white;
        }
      }
    }

    .admin-profile {
      // border: 1px solid red;
      display: flex;
      // justify-content: space-around;
      gap: 20px;

      // PROJECT STYLING

      $border-radius: 3px;
      $primary: #f2f2f2;
      $active: #0f3787;
      $animation: pulse;

      .profile-dropdown {
        display: inline-block;
        display: flex;
        position: relative;
        top: 0px;
        left: 0px;
        background: $primary;
        margin: auto;
        font-weight: 500;
        cursor: pointer;
        font-size: 18px;
        border-radius: $border-radius;
        -webkit-user-select: none; /* Chrome all / Safari all */
        -moz-user-select: none; /* Firefox all */
        -ms-user-select: none; /* IE 10+ */
        user-select: none; /* Likely future */

        * {
          -webkit-user-select: none; /* Chrome all / Safari all */
          -moz-user-select: none; /* Firefox all */
          -ms-user-select: none; /* IE 10+ */
          user-select: none; /* Likely future */
        }

        input[type="checkbox"] {
          display: none;
          &:checked {
            & ~ ul {
              display: block;
              animation: $animation 0.5s;
              cursor: pointer;
            }

            & ~ img {
              background: $active;
            }

            & ~ label {
              background: $active;
              cursor: pointer;

              i {
                color: $primary;
              }

              &:after {
                content: "";
                position: absolute;
                top: 100%;
                right: calc(50% - 10px);
                display: block;
                border-style: solid;
                border-width: 7px 10px 0 10px;
                border-color: $active transparent transparent transparent;
                width: 0;
                height: 0;
                z-index: 999;
              }
            }
          }
        }

        // img {
        //   display: inline-block;
        //   background: darken($primary, 10%);
        //   height: 2.5rem;
        //   vertical-align: middle;
        //   margin-right: 1rem;
        //   margin: 0.5rem 0.75rem 0.5rem 0.5rem;
        //   border-radius: 50%;
        // }
        .account-circle {
          display: inline-block;
          //  background: darken($primary, 10%);
          height: 2.5rem;
          vertical-align: middle;
          margin-right: 1rem;
          margin: 0.5rem 0.75rem 0.5rem 0.5rem;
          border-radius: 50%;
          margin-bottom: 20px;
        }

        svg {
          width: 100%;
          height: 100%;
          // background-color: #fff;
          color: #0f3787;
          margin-bottom: 10px;
          position: relative;
          // bottom: 10px;
        }

        span {
          display: inline-block;
          vertical-align: sub;
          margin-right: 2rem;
          overflow: hidden;
          width: 170px;
          white-space: nowrap;
          text-overflow: ellipsis;
          margin-top: 18px;
          color: #0f3787;
        }

        ul {
          display: none;
          list-style: none;
          padding: 0;
          margin: 0;
          background: #f2f2f2;
          // cursor: pointer;
          position: absolute;
          top: 100%;
          right: 0;
          width: 100%;
          border-radius: $border-radius;

          li {
            a {
              display: block;
              padding: 0.75rem 1rem;
              text-decoration: none;
              color: darken($primary, 50%);
              font-size: 1rem;

              i {
                font-size: 1.3rem;
                vertical-align: middle;
                margin: 0 0.75rem 0 -0.25rem;
              }

              &:hover {
                background: darken($primary, 5%);
              }
            }

            &:first-child a:hover {
              border-radius: $border-radius $border-radius 0 0;
            }

            &:last-child a:hover {
              border-radius: 0 0 $border-radius $border-radius;
            }
          }
        }

        & > label {
          position: relative;
          top: 0px;
          height: 3.5rem;
          display: block;
          text-decoration: none;
          background: transparent;
          color: #333;
          box-sizing: border-box;
          padding: 0.9rem;
          float: right;
          border-radius: 0 $border-radius $border-radius 0;

          i {
            color: darken($primary, 25%);
            font-size: 1.75rem;
          }
        }

        &:after {
          content: "";
          display: table;
          clear: both;
        }
      }

      /// TEMPLATE STYLING
      $background: #2c3e50;

      body {
        background: $background;
        // font-family: Lato;
        // font-family: 'Poppins', sans-serif;
        font-family: "Open Sans", sans-serif;
      }

      h1 {
        text-align: center;
        font-size: 3rem;
        color: rgba(0, 0, 0, 0.5);
        text-transform: uppercase;
        margin: 2rem 0 0;
        letter-spacing: 0.5rem;
      }

      .container {
        width: 80%;
        margin: 4rem auto 2rem;

        .half {
          width: 50%;
          float: left;
          margin-bottom: 2rem;
        }

        &:after {
          content: "";
          display: table;
          clear: both;
        }
      }

      p.subtitle {
        color: rgba(0, 0, 0, 0.5);
        font-weight: bold;
        text-align: center;
        margin: 0.5rem 0 2rem;
        letter-spacing: 0.1rem;
      }

      .background {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        opacity: 0.25;
        z-index: -1;
        background: url(https://ar1web-com.googlecode.com/svn/Bg/bg63.gif);
      }
    }

    /* navigation */
    .navbar-collapse {
      flex-grow: 0 !important;
    }

    .navbar-nav {
      display: flex;
      align-items: center;
    }

    .navbar-nav li {
      padding: 0 10px;
    }

    .navbar-collapse a {
      /* color: white !important; */
      font-size: 18px;
    }

    .navbarHeadC {
      /* color: white; */
    }

    .navbarHeadC a {
      /* color: white; */
    }

    .upload-btn a button {
      /* color: green !important; */
      position: relative;
      right: 90px;
    }

    ul.navbar-nav.ml-auto {
      margin-right: 80px;
    }

    .custom-file-button {
      width: 460px;
      height: 60px;
      margin-top: 10px;
      padding: 10px 15px;
      border-radius: inherit;
      border-style: solid;
      border-width: 1px;
      overflow: hidden;
      min-width: 0%;
      border-color: rgba(0, 0, 0, 0.23);
    }
  }
}
