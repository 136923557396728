.dashboard-wrapper {
  background: #f3f6f9;

  .dashboard-page {
    width: 80%;
    margin: 0 auto;
    position: relative;
    // bottom: 650px;
    left: 100px;
    z-index: 99;
  }
}
