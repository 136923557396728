.Form-fields {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // margin-top: 40px;

    .hide{
        display: none;
    }

    .show{
        display: block;
        margin-top: -20px;
        text-align: left;
        margin-bottom: 20px;
    }

    .activate{
        -webkit-text-security:none;
    }

    .notactivate{
        -webkit-text-security:disc;
    }

    .Form_inner_fileds {
        width: 98vw;
        height: 90vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        // @media screen and (min-width:500px) and (max-width:700px) {
        //     width: 90%;
        // }

        // @media screen and (min-width:100px) and (max-width:500px) {
        //     width: 98%;
        // }
    }

    .label {
        text-align: left;
        // width: 70%;
        font-weight: 500;

        @media screen and (min-width:100px) and (max-width:500px) {
            font-size: 15px;
        }
    }

    input {
        width: 100%;
        padding: 14px;
        margin-top: 15px;
        margin-bottom: 30px;
        border-radius: 0px;
        padding-right: 43px;
        border: 1px solid grey;

        @media screen and (min-width:100px) and (max-width:500px) {
            font-size: 13px;
        }

        &:focus-visible {
            border: 1px solid gray !important;
            border-radius: 0px !important;
            outline: none;
        }
    }

    .icon {
        position: absolute;
        color: #0F3787;
        margin-top: 28px;
        margin-left: -37px;

        svg {
            width: 25px;
            height: 25px;
        }
    }

    button {
        width: 100%;
        padding: 10px;
        border: 0px;
        background-color: #0F3787;
        color: white;
        font-size: 18px;
        // font-weight: 500;
        margin-bottom: 30px;

        @media screen and (min-width:100px) and (max-width:500px) {
            font-size: 13px;
        }
    }

    // thanks popup 
    .modal-header {
        border-bottom: 1px solid #fff !important;
        display: flex;
        justify-content: end;

    .close {
        width: 8%;
    }
    button {
        width: 100%;
        border: 0px;
        color: black;
        background-color: #fff;
    }
    }
    .modal-body {
      font-size: 18px;
      font-weight: 500;
      text-align: center;
    }
    .modal-footer {
      border-top: 1px solid #fff;
      display: flex;
      justify-content: center;
      margin-top: 37px;


      button {
        width: 20%;
        background-color: #0F3787 !important;

      }
    }
}