.loader_wrapper {
    position: absolute !important;
    width: 100% !important;
    height: 100% !important; 
    overflow: hidden !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important; 
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 999 !important;


    .loader_inner_wrapper {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important; 

        img {
            width: 60px !important;
            height: 60px !important;
        }

    }

}