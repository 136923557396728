.pending-list {
    border: 1px solid;
    height: 800px;
    padding: 20px;
}
.published-list {
    border: 1px solid;
    height: 800px;
    padding: 20px;
}
.rejected-list {
    border: 1px solid;
    height: 800px;
    padding: 20px;
}
.course_wrapper {
    padding-top: 100px;
}