.published_page_wrapper {
  // font-family: 'Poppins', sans-serif;
  font-family: "Open Sans", sans-serif;
  div.dataTables_wrapper div.dataTables_length select {
    //  color: brown !important;
    width: 50px !important;
  }

  .navbar-wrapper {
    // margin-top: -100px;
  }

  .published_table_wrapper {
    // position: absolute;
    left: 21vw;
    top: 25vh;
    width: 100%;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    // margin: 0 auto;
    // margin-top: 100px;
    // margin-right: 150px;
    // padding-top: 150px;

    h1 {
      font-size: 18px;
      font-weight: 600;
      text-align: left;
      // border: 1px solid;
      width: 60%;
      margin: 0 auto;
      margin-top: 20px;
    }

    .custom-datatable {
      width: 70%;
      margin: 0 auto;
      margin-left: 400px;
    }
    @media only screen and (max-width: 505px) {
      .published_table_wrapper {
        z-index: -10;
        left: 30vw;
        width: 50%;
        overflow: scroll;

        h1 {
          font-size: 12px;
        }
      }
    }
  }
  .form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem;

    .form-check-input {
      cursor: pointer;
      width: 45px;
      height: 20px;
      margin-top: 0.25em;
      vertical-align: top;
      // background-color: #D01717;
      background-color: tomato;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      // border: 1px solid rgba(0,0,0,.25);
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      -webkit-print-color-adjust: exact;
      color-adjust: exact;
    }

    .form-check-input:checked {
      background-color: #43b841;
      // border-color: #43B841;
    }

    // .form-switch
    .form-check-input:checked {
      background-position: right center !important;
      // background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e);
    }
    // .form-switch
    .form-check-input {
      // width: 2em;
      margin-left: -2.5em;
      // background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e);
      background-position: left center !important;
      border-radius: 2em;
      transition: background-position 0.15s ease-in-out;
    }
  }
}
