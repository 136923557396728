.main {
    width: 100vw;
    height: 92vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .middle {
        // border-style: solid;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: white;
        padding: 40px 20px;
        border: none;
        width: auto;
        // min-width: 40vw;
        width: 80%;
        max-width: 700px;
        box-shadow:
            0 2px 5px 0 rgba(0, 0, 0, 0.16),
            0 2px 10px 0 rgba(0, 0, 0, 0.12);

        .inner1,
        .inner2 {
            width: 100%;
        }

        .inner2 {
            border-top: 0;
            width: 100%;

            .email {
                width: 100%;
                padding: 10px 10px;
                font-size: 1em;
                color: black;
                outline: none;
            }

            button {
                font-weight: 700;
                color: white;
                background-color: #003e83;
                border-radius: 10px;
                // border-width: o;
                border: none;
                font-size: 1rem;
                width: 70%;
                height: 60px;
                margin: 2% 5%;

                button:hover {
                    box-shadow:
                        0 2px 5px 0 rgba(0, 0, 0, 0.16),
                        0 2px 10px 0 rgba(0, 0, 0, 0.12);
                }
            }
        }
    }
}
