.sidebar-wrapper {
  transition: width 0.3s ease;
  background: #192b3c;
  color: #fff;
  // height: 124vh;
  height: 100%;
  left: 0;
  max-width: 17%;
  min-width: 100px;
  overflow: hidden;
  padding: 10px;
  // position: absolute;
  position: fixed;
  z-index: 9999;
  top: 85px;
}

.ham-wrap {
  display: flex;
  justify-content: end;

  .hamburger-btn {
    border: none;
    background-color: transparent;
    outline: none;

    svg {
      color: #f0f0f0;
      height: 30px;
      width: 30px;
    }
  }
}

.admin {
  font-weight: 600;
  text-align: left;
  margin-left: 0px;
  background-color: white;
  color: #192b3c !important;
  border-radius: 10px;
  padding: 5px;
  max-width: 100%;

  text-align: center;
  color: #fff;
  margin-bottom: 20px;
}

.admin .collapsed {
  display: none;
}

@media only screen and (max-width: 1150px) {
  .admin {
    position: relative;
    left: 12v;
    font-size: 14px;
  }
}

.sidebar ul {
  color: #fff !important;
  margin-left: -5px;
  margin-top: 40px;
  text-align: left;
  width: 100%;
  padding: 0px;
}

.sidebar li {
  -webkit-font-smoothing: antialised;
  color: #fff;
  cursor: pointer;
  font-family: Open Sans, sans-serif;
  font-size: 17px;
  list-style-type: none;
  margin-bottom: 16px;
  padding: 10px;
  text-decoration: none;
}

.sidebar li a {
  -webkit-font-smoothing: antialised;
  color: #fff;
  cursor: pointer;
  font-family: Open Sans, sans-serif;
  font-size: 17px;
  list-style-type: none;
  margin-bottom: 16px;
  //   padding: 30px 30px 30px 0;
  text-decoration: none;
  transition: 0.3s;
}

.sidebar li a:hover {
  color: #4da1ff;
  width: 100%;
  height: auto;
  font-weight: 500;
  border-radius: 0.5rem;
  transition: 0.3s;
  font-size: 17px;
}

.sidebar ul li a.active {
  background-color: #f0f0f0;
  border-radius: 0.5rem;
  color: #0f3787;
  display: block;
  min-width: 70px;
  padding: 10px;
}

.sidebar {
  width: 100%;
  padding-top: 20px;
}

.exit {
  position: absolute;
  bottom: 0;
  width: 100%;
}

/* Collapsed sidebar styles */
.collapsed {
  width: 60px; /* Collapsed sidebar width */
}

.collapsed label {
  left: 5px; /* Adjust position of hamburger icon for collapsed state */
}

/* Sidebar collapse/expand button styles */
.toggle-btn {
  //   cursor: pointer;
  //   height: 40px;
  //   background-color: #252525; /* Button background color */
}



.toggle-btn svg {
  color: #fff; /* Button icon color */
  font-size: 30px;
  margin-right: 10px;

  border-radius: 50%;
  padding: 3px;
  color: #0f3787;
  background-color: #fff;
}
